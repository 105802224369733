



























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import DataTable from '@/components/Admin/DataTable.vue';
import Modify from './Modify.vue';
import dayjs from 'dayjs';
import { msToTimeStr } from '@/util';
import { rootModule } from '@/store';

@Component({
  components: {
    DataTable,
    Modify,
  },
})
export default class extends Vue {
  eventLog: API.EventLog.GET[] = []; // Only stores the current page of entries!
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifyEntry: API.EventLog.GET | null = null; // Event Log Entry to be modified/deleted.
  additionalParams: Partial<API.TypesMap['eventLog']['params']> = {};
  options = {
    apiName: 'eventLog',
    socketName: 'eventLogEntryModified',
    idStr: 'id',
    embeds: [
      { name: 'card', socketName: 'playerCardModified', idStr: 'cardId' },
      { name: 'player', socketName: 'playerModified', idStr: 'playerId' },
      { name: 'user', socketName: 'userModified', idStr: 'userId' },
      { name: 'event', socketName: 'eventModified', idStr: 'eventId' },
    ],
    headers: [
      { text: 'Player', value: 'user.name' },
      { text: 'Event', value: 'event.name' },
      { text: 'Card Message', value: 'card.msg' },
      { text: 'Sent From Tech', value: 'fromTech' },
      { text: 'Cleared By Caster', value: 'cleared' },
      { text: 'Automatically Sent To Casters', value: 'autoSent' },
      { text: 'Message', value: 'msg' },
      { text: 'Clip Attached', value: 'clipAttached' },
      { text: 'Created', value: 'creationTimestamp' },
    ],
    modifyComponent: Modify,
  };
  dayjs = dayjs;
  msToTimeStr = msToTimeStr;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }
  get selectedPlayer(): number | null { return rootModule.selectedPlayer; }

  @Watch('selectedPlayer', { immediate: true })
  onSelectedPlayerChange(val: number | null): void {
    if (typeof val === 'number') Vue.set(this.additionalParams, 'playerId', val);
    else Vue.set(this.additionalParams, 'playerId', undefined);
  }
}
