



























import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import ModifyDialog from '@/components/Admin/ModifyDialog.vue';
import TextInput from '@/components/Admin/TextInput.vue';
import EventInput from '@/components/Admin/EventInput.vue';
import SwitchInput from '@/components/Admin/SwitchInput.vue';
import PlayerInput from '@/components/Admin/PlayerInput.vue';
import { removeDBTimestamps } from '@/util';
import { rootModule } from '@/store';
import { apiModify } from '@/api';

@Component({
  components: {
    ModifyDialog,
    TextInput,
    EventInput,
    SwitchInput,
    PlayerInput,
  },
})
export default class extends Vue {
  @PropSync('dialog', { type: Boolean, required: true }) dialogProp!: boolean;
  @Prop(Object) readonly modifyItem!: API.EventLog.GET | undefined;
  entry: API.EventLog.POST = {};
  entryId: number | null = null;
  err: boolean | string = false;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  @Watch('dialog')
  onDialogChange(val: boolean): void {
    if (val) {
      if (this.modifyItem) {
        this.entry = removeDBTimestamps(this.modifyItem);
        this.entryId = this.modifyItem.id;
      } else {
        this.entry.eventId = this.selectedEvent ?? undefined;
        this.entry.fromTech = true;
      }
    }
  }

  cancel(): void {
    this.entry = {};
    this.entryId = null;
    this.err = false;
  }

  async save(): Promise<void> {
    try {
      const { data } = await apiModify('eventLog', this.entryId, this.entry);
      this.entry = {};
      this.entryId = null;
      this.err = false;
      this.dialogProp = false;
      this.$emit('post', data);
    } catch (err) {
      const { data } = err.response;
      if (data && data.error && !Array.isArray(data.error)) {
        this.err = data.error;
      } else {
        this.err = 'Some server error occured';
      }
    }
  }
}
